import { graphql } from 'gatsby';
import React from 'react';
import EpisodePlayer from '../components/EpisodePlayer';
import { Container } from '../components/Grid';
import Hero from '../components/Hero';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Quicklinks from '../components/Quicklinks';
import Section from '../components/Section';

const VideoTopicSeries = ({ data }) => (
  <Layout title={'Video Topic Series'}>
    <Hero
      mediaSource="vts-hero.jpeg"
      textOverlay="VIDEO TOPIC SERIES"
      className="mb-0"
    />

    {/* Intro */}
    <Section noPadding className="vts-intro">
      <Container fullWidth className="vts-intro__container" noPadding>
        <div className="vts-intro__container__text">
          <Quicklinks
            links={[
              {
                target: '#videos',
                label: 'Sounds of STEAM',
                clickTarget: '#sounds-of-steam',
              },
              {
                target: '#videos',
                label: 'Light it Up!',
                clickTarget: '#light-it-up',
              },
              {
                target: '#videos',
                label: 'Country Music Entrepreneurs',
                clickTarget: '#country-music-entrepreneurs',
              },
            ]}
          />
          <h1 className="mt-2">MEET THE UNSUNG HEROES OF COUNTRY MUSIC</h1>
          <p className="large mt-2 pb-3">
            Give students an up-close look at what it takes to orchestrate the
            unforgettable sights and sounds of Country Music with a series of
            subject matter expert profile videos. Students will go on the job
            with a variety of Country Music professionals who all use STEAM
            skills to shine in their role.
          </p>
        </div>
        <div className="vts-intro__container__img-div">
          <Image
            className="vts-intro__container__img-div__img"
            filename="vts-graphic-singing.svg"
          />
        </div>
      </Container>
    </Section>

    {/* Videos */}
    <Section noPadding className="vts-videos">
      <Container fullWidth id="videos" noPadding className="bg-white">
        <EpisodePlayer
          playerLabel={
            <>
              <i>This is STEAM Country!</i> &nbsp;Video Topic Series
            </>
          }
          description={''}
          className="pt-0"
          episodes={data.allVideoTopicsYaml.edges.map((edge) => edge.node)}
          about={
            <>
              <p>
                Step into the shoes of real Country Music professionals with a
                series of video vignettes spotlighting the diverse talent
                working behind-the-scenes of Country Music.
              </p>

              <p>
                Discover the secrets to Country Music’s popularity by going
                inside the sights, sounds, and experiences of a real Country
                performance. Get a behind-the-scenes pass to CMA Fest and
                introduce students to the dedicated Country Music professionals
                who help make the magic happen from off the stage. The Video
                Topic Series gives students a unique look at how STEAM skills
                can be harnessed to create success and accomplish amazing things
                while working in harmony with others.
              </p>
            </>
          }
          guideImg="vts-resource-EdGuide.jpg"
          guideTitle="This is STEAM Country!  |  Educator Guide"
          guideDescription="Explore tools, tips, and strategies for implementing this three-part Video Topic Series in any learning environment, including classroom activations for each video."
          guideAudience="Grades 3–8"
          guideDownloadUrl="/docs/VTS-EdGuide.pdf"
          guideSize="484 KB"
        />
      </Container>
    </Section>
  </Layout>
);

export const query = graphql`
  query VideoTopicsQuery {
    allVideoTopicsYaml (filter: {topic: {eq: "STEAM"}}){
      edges {
        node {
          id
          topic
          dexSrc
          conversationStarters
          posterSrc
          descriptionTitle
          description
          title
          duration
          subject
          actions {
            label
            download
            metadata
          }
          quote {
            text
            source
            backgroundImage
            className
            width
          }
        }
      }
    }
  }
`;

export default VideoTopicSeries;
